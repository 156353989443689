// @flow

import React from 'react';
import PropTypes from 'prop-types';
import MarkdownDocs from 'metadata-react/Markdown/MarkdownDocsLight';
import markdown from './About.md';
import {description} from '../App/menu_items';
import Wrapper from '../App/Wrapper';
import withStyles from '../CalcOrder/FrmList/styles';
import CloseBtn from 'metadata-react/App/CloseButton';

function Page(props) {
  const {classes, handlers, history} = props;
  return <Wrapper
    title="Техническая информация"
    handlers={handlers}
    className={classes.content}
    CustomBtn={<CloseBtn handleClose={() => history.goBack()}/>}
    hide_help
  >
    <MarkdownDocs key="text" markdown={markdown} descr={description} canonical="/about"/>
  </Wrapper>;
}

Page.propTypes = {
  classes: PropTypes.object,
  handlers: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(Page);
