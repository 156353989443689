/**
 * Заголовок с кнопками полноэкранного режима
 *
 * @module Fullscreenable
 *
 * Created by Evgeniy Malyarov on 25.09.2019.
 */

import React from 'react';
import PropTypes from 'prop-types';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import cn from 'classnames';
import {path} from './menu_items';
import Tip from 'metadata-react/App/Tip';

const threshold = 48;

const useStyles = makeStyles(({spacing}) => ({
  space: {
    flex: '1 1 auto',
    padding: spacing(),
  },
  flex: {
    display: 'flex',
  },
  height: {
    minHeight: threshold,
  },
  fixed: {
    position: 'fixed',
    width: '100%',
    paddingRight: spacing(),
    top: 0,
    left: 0,
    zIndex: 1000,
  },
  shadow: {
    paddingTop: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  }
}));

export default function Wrapper({title = 'Быстрые окна', handlers, children, CustomBtn, className, hide_help, scroll_shadow}) {

  if(typeof $p != 'undefined' && $p.wsql.get_user_param('iface_kind') !== 'quick') {
    return children;
  }

  const classes = useStyles();

  const trigger = scroll_shadow && useScrollTrigger({
    //target: window ? window() : undefined,
    disableHysteresis: true,
    threshold,
  });

  return <div>
    <div className={cn(classes.flex, classes.height, trigger && classes.shadow, scroll_shadow && classes.fixed)}>
      <Typography variant="subtitle2" color="primary" className={classes.space}>{title}</Typography>
      {!hide_help && <Tip title="О программе">
        <IconButton
          onClick={() => handlers.handleNavigate(path('about'))}
          color="inherit"
        >
          <HelpIcon/>
        </IconButton>
      </Tip>}
      {CustomBtn}
    </div>
    {scroll_shadow && <div className={classes.height}/>}
    {className ? <div className={className}>{children}</div> : children}
  </div>;
}

Wrapper.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  handlers: PropTypes.object,
  CustomBtn: PropTypes.node,
  children: PropTypes.node,
  hide_help: PropTypes.bool,
  scroll_shadow: PropTypes.bool,
};

