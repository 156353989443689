import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App/AppView';
import Metadata from './components/App/Metadata';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Metadata App={App} />);

const handlePageChange = (loc, method) => {
  const {top} = window;
  if(top !== window) {
    top.postMessage(`[iFrameSizer]metadata:${method}:${'/quick' + loc.pathname}:${document.title}`, '*');
  }
};
//history.listen(handlePageChange);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

//serviceWorker.unregister();
serviceWorker.register({
  onUpdate() {
    alert('Код программы обновлён, необходимо перезагрузить страницу');
    location.reload();
  },
});

