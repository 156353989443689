import React from 'react';
import Repl from './Repl';

class DumbScreen extends React.Component {

  renderRepl(footer) {
    let {repl, page} = this.props;
    const res = [];
    for (const dbs in repl) {
      if(!repl[dbs].end_time) {
        res.push(<Repl key={dbs} info={repl[dbs]}/>);
      }
    }
    if(!res.length && page && page.docs_written < page.total_rows) {
      const info = {db: 'ram', docs_read: page.docs_written, pending: page.total_rows - page.docs_written};
      res.push(<Repl key={'ram'} info={info}/>);
    }
    if(!res.length && footer) {
      res.push(<div key="footer">{footer}</div>);
    }
    return res;
  }

  render() {

    let {page, repl, title} = this.props;
    const over = page && page.limit * page.page > page.total_rows;
    let footer = 'Запрос справочников с сервера...';
    if(page && (page.total_rows || page.text)) {
      footer = page.text || `Такт №${page.page}, загружено ${page.docs_written} из ${page.total_rows} объектов`;
    }

    return <div className={page && page.doc_ram_loaded ? 'splash' : 'splash splash-animation'}>
      <div className="description">
        {[
          <h1 key="name" itemProp="name">Быстрые окна</h1>,
          <p key="category">Категория: <span itemProp="applicationSubCategory">CRM, CAD, E-Commerce</span></p>,
          <p key="platform">Платформа: <i className="fa fa-chrome" aria-hidden="true"></i> браузер Chrome для <span
            itemProp="operatingSystem">Windows 8, 10 | Android | Mac | iOS</span>
          </p>,
          <div key="description" itemProp="description">
            <p>Веб-сервис <a href="https://flowcon.oknosoft.ru/articles/implementation_of_the_windowbuilder"
                             title="Программы для оконных заводов и дилеров"
                             target="_blank" rel="noopener noreferrer">Заказ дилера</a>, предназначен для:</p>
            <ul>
              <li>Расчета геометрии, спецификации и стоимости оконных и витражных конструкций (ПВХ, Дерево, Алюминий)</li>
              <li>Aвтоматизации работы менеджеров и дилеров</li>
              <li>Ускорения и упрощения подготовки производства</li>
              <li>Планирования и контроля на всех этапах</li>
            </ul>
          </div>
        ]}
      </div>

      <div style={{paddingTop: '30px'}}>
        <div>{title}</div>
        {this.renderRepl(page && footer)}
      </div>

    </div>;
  }
}

export default DumbScreen;
