import createMuiTheme from '@material-ui/core/styles/createTheme';

const theme = createMuiTheme({

  palette: {
    primary: {
      main: '#00AC4D',
      primary: '#00AC4D',
      tertiary: '#F0F0F0',
      text: {
        primary: '#00AC4D'
      }
    },
  },

  mixins: {
    toolbar: {
      minHeight: 48,
    }
  },

  typography: {
    useNextVariants: true,
    subtitle2: {
      fontSize: '1.1rem',
    },
    fontFamily: ['"Open Sans"', 'Arial', 'sans-serif'].join(','),
  },


  overrides: {


    MuiMenuItem: {
      root: {
        whiteSpace: 'normal'
      }
    },
  },

});

export default theme;

