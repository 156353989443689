import withStyles from '@material-ui/core/styles/withStyles';

const styles = ({spacing}) => ({
  content: {
    padding: spacing(),
  },
  margin: {
    marginTop: spacing(),
    marginBottom: spacing(2),
    width: 300,
    height: 40,
  },
  padding: {
    paddingLeft: spacing(),
    paddingRight: spacing(),
    justifyContent: 'left',
  },
  paddingUl: {
    paddingLeft: spacing(2),
  },
});

export default withStyles(styles);
