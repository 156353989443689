import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Router, Switch, Route, Redirect} from 'react-router';
import Login, {FrmLogin} from 'metadata-react/FrmLogin/Proxy';  // логин и свойства подключения
import NeedAuth from 'metadata-react/App/NeedAuth'; // страница "необходима авторизация"

import withWindowSize from 'metadata-react/WindowSize';

import DumbScreen from '../DumbScreen';  // заставка "загрузка занных"
import DataRoute from './DataRoute';                // вложенный маршрутизатор страниц с данными
import MarkdownRoute from '../Markdown/Route';      // вложенный маршрутизатор страниц с Markdown, 404 живёт внутри Route
import Builder from '../Builder';                   // графический редактор

import withStyles from './styles';

import {item_props, path} from './menu_items'; // массив элементов меню и метод для вычисления need_meta, need_user по location.pathname


// основной layout
class AppView extends Component {

  constructor(props, context) {
    super(props, context);
    this.handleAlertClose = this.handleDialogClose.bind(this, 'alert');
    const iprops = item_props();
    this.state = {
      need_meta: !!iprops.need_meta,
      need_user: !!iprops.need_user,
      mobileOpen: false,
    };
  }

  shouldComponentUpdate(props, {need_user, need_meta}) {
    const iprops = item_props();
    let res = true;

    if(need_user != !!iprops.need_user) {
      this.setState({need_user: !!iprops.need_user});
      res = false;
    }

    if(need_meta != !!iprops.need_meta) {
      this.setState({need_meta: !!iprops.need_meta});
      res = false;
    }

    return res;
  }

  handleDialogClose(name) {
    this.props.handleIfaceState({component: '', name, value: {open: false}});
  }

  handleReset() {
    location.replace(path(''));
  }

  handleDrawerToggle = () => {
    const {state} = this;
    this.setState({mobileOpen: !state.mobileOpen});
  };

  handleDrawerClose = () => {
    this.setState({mobileOpen: false});
  };

  render() {
    /* eslint-disable-next-line */
    const {classes, history, handleNavigate, handleIfaceState, ...props} = this.props;
    const {meta_loaded, doc_ram_loaded, user, couch_direct, offline, title, idle, page} = props;
    const iprops = item_props();
    const handlers = {handleNavigate, handleIfaceState};

    let need_auth = meta_loaded && iprops.need_user && ((!user.try_log_in && !user.logged_in) || (couch_direct && offline));
    if(need_auth && !couch_direct && props.complete_loaded) {
      const {current_user} = $p;
      if(current_user && current_user.name == user.name) {
        need_auth = false;
      }
    }

    const auth_props = {
      key: 'auth',
      handleNavigate: handleNavigate,
      handleIfaceState: handleIfaceState,
      offline: couch_direct && offline,
      user,
      title,
      idle,
      disable: ['google'],
      ret_url: path(''),
    };

    const wraper = (Component, routeProps) => {
      return <Component {...props} handlers={handlers} {...routeProps}/>;
    };

    return [

      // основной layout
      // основной контент или заставка загрузки или приглашение к авторизации
      // need_auth || idle ?
      idle ?
        (
          <NeedAuth {...auth_props} ComponentLogin={FrmLogin}/>
        )
        :
        (
          (((iprops.need_meta && !meta_loaded) || (iprops.need_user && !props.complete_loaded))) ?
            <DumbScreen key="dumb"
              title={doc_ram_loaded ? 'Подготовка данных в памяти...' : 'Загрузка...'}
              page={{text: doc_ram_loaded ? `Почти готово` : `${(page && page.synonym) || ''}...`, doc_ram_loaded}}
            />
            :
            <Router key="switch" history={history}>
              <Switch>
                <Route exact path={path('')} render={() => <Redirect to={path('doc.calc_order/list')}/>}/>
                <Route path={path('o/')} render={() => <Redirect to={location.pathname.replace('/o/', '/doc.calc_order/')}/>}/>
                <Route path={`${path('builder')}/:ref([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`} render={(props) => wraper(Builder, props)}/>
                <Route path={`${path('')}:area(doc|cat|ireg|cch|rep).:name`} render={(props) => wraper(DataRoute, props)}/>
                <Route path={path('login')} render={(props) => <Login {...props} {...auth_props} />}/>
                <Route render={(props) => wraper(MarkdownRoute, props)}/>
              </Switch>
            </Router>
        ),

    ];
  }
}

AppView.propTypes = {
  handleNavigate: PropTypes.func.isRequired,
  handleIfaceState: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(withWindowSize(AppView));
