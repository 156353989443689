/**
 * Подписка на события метадаты и установка свойств
 *
 * Created by Evgeniy Malyarov on 14.02.2021.
 */

import {lazy} from './lazy';

export const init_state = {
  meta_loaded: false,
  common_loaded: false,
  doc_ram_loaded: false,
  complete_loaded: false,
  fetch: false,
  idle: false,
  page: {
    total_rows: 0,
    docs_written: 0,
    page: 1,
  },
  offline: false,
  title: 'Подготовка данных',
  error: null,
  user: {
    logged_in: false,
    stop_log_in: false,
    try_log_in: false,
    log_error: '',
  },
  snack: null,
  alert: null,
  confirm: null,
  wnd_portal: null,
};

export function actions(elm) {

  // скрипт инициализации структуры метаданных и модификаторы
  return Promise.resolve()
    .then(() => import('../../metadata'))
    .then((module) => module.init(elm))
    .then(() => {
      // font-awesome, roboto и стили metadata подгрузим асинхронно
      import('metadata-react/styles/roboto/font.css');
      import('font-awesome/css/font-awesome.min.css')
        .then(() => import('../../styles/global.css'));
    })
    .then(() => {
      const {adapters: {pouch}, md, ui} = $p;
      elm.setState({common_loaded: true});
      const {handleNavigate, handleIfaceState} = elm;
      ui.dialogs.init({handleIfaceState, handleNavigate, lazy});

    });
}
